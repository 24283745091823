import { forwardRef } from "react";
import classNames from "classnames";
import { FacilityData } from "../../types";

const FacilityBrandBox = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> &
    Pick<FacilityData, "facilityShortName" | "brandName">
>(({ className, facilityShortName, brandName, ...divProps }, ref) => {
  return (
    <div
      ref={ref}
      {...divProps}
      className={classNames("py-5 px-[15px] md:p-0 box-content", className)}
    >
      <div className="flex flex-col gap-[15px]">
        {facilityShortName && (
          <div className="text-[1.1rem] font-semibold text-gray-800 text-wrap">
            {facilityShortName}
          </div>
        )}
        {brandName && (
          <div className="text-lg font-normal text-gray-800 uppercase text-wrap">
            {brandName}
          </div>
        )}
      </div>
    </div>
  );
});

export default FacilityBrandBox;
