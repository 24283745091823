import { LoaderFunction, useLoaderData } from "react-router-typesafe";
import {
  CurrentPageChangedEvent,
  Model,
  PageModel,
  SurveyModel,
  ValueChangedEvent,
  DynamicPanelRemovingEvent,
  UpdateQuestionCssClassesEvent,
  LoadChoicesFromServerEvent,
  MatrixRowRemovingEvent,
  QuestionDropdownModel,
  ValueChangingEvent,
  CurrentPageChangingEvent,
  CompletingEvent,
  QuestionExpressionModel,
} from "survey-core";
import { useState, useCallback, useEffect, useRef } from "react";
import { T } from "@tolgee/react";
import { toast } from "react-toastify";
import { useHydrateAtoms } from "jotai/utils";
import { useAtom, useAtomValue } from "jotai";
import { AxiosResponse } from "axios";
import {
  isNewQuestionnaireAtom,
  languageQuestionAtom,
  explainerContentQuestionAtom,
  questionnaireNameAtom,
  uploadedFilesSetAtom,
  sidebarContentAtom,
  facilityDataAtom,
} from "../jotai-atoms";
import "survey-core/defaultV2.min.css";
import "../components/survey";
import "../register-icons";
import "../override-locale";
import "../register-properties";
import "../validators";
import { CheckCircle, XCircle } from "react-feather";
import {
  compareFilteredChanges,
  getAllVisibleQuestionValueNames,
  getSideBarContent,
  isBoolean,
  isCheckbox,
  isDisplayMode,
  isDropdown,
  isDynamicPanel,
  isExpression,
  isFile,
  isMatrixDropdown,
  isMatrixDynamic,
  isPageTitle,
  isRemovingRowFromDynamicPanelOrMatrix,
  prepareQuestions,
  prepareSurvey,
  removeKeysFromArray,
  removeKeysFromObject,
  replaceChoicesUrlParamValuesWithAnswers,
  setSurveyInitialData,
} from "../utils";
import { ResourceType } from "../interfaces";
import {
  MenuDrawerTemplate,
  SurveyPageTemlpate,
} from "../components/templates";
import { ApiService } from "../services";
import { customCss } from "../survey-css";
import { themeJson } from "../theme";
import { ApiServiceProvider } from "../components/providers";
import { SidebarContent } from "../types";

export const surveyPageLoader = (async ({ params, request }: any) => {
  let sidebarContent: SidebarContent = { navigationItems: [] };
  const { gatherProcessId } = params;
  const apiService = new ApiService({
    gatherProcessId,
  });
  await apiService.fetchResourceType();
  const { data: schema } = await apiService.fetchSchema();
  const questionnaireIdKey = sessionStorage.getItem("questionnaire-id-key");
  const searchParams = new URL(request.url).searchParams;
  let isReadonlyView = searchParams.get("readonly") === "true";

  let questionnaireId = searchParams.get("ques_id");

  if (questionnaireId) {
    apiService.questionnaireId = questionnaireId;
  } else if (schema.questionnaireId) {
    apiService.questionnaireId = schema.questionnaireId;
  }

  if (questionnaireIdKey && !apiService.questionnaireId) {
    apiService.questionnaireId = questionnaireIdKey;
  }

  const survey = new Model();
  survey.applyTheme(themeJson);
  survey.css = customCss;
  survey.navigateToUrl = "/complete";
  survey.fromJSON(schema);
  survey.showPreviewBeforeComplete = "showAllQuestions";
  try {
    const { data } = await apiService.getSideBarContent();
    sidebarContent = data.navigationItems.length
      ? data
      : getSideBarContent(survey, apiService.resourceType);
  } catch (e) {
    sidebarContent = getSideBarContent(survey, apiService.resourceType);
  }
  prepareSurvey(survey);
  prepareQuestions(survey.getAllQuestions());
  const explainerPage = survey.getPageByName("explainer-page");
  let contentQuestion;
  if (explainerPage) {
    contentQuestion = explainerPage.questions.find((q) =>
      q.name.includes("explainer-page-content")
    );
  }
  if (apiService.questionnaireId !== "") {
    survey.removePage(explainerPage);
    const { data: answers } = await apiService.fetchAnswers();

    setSurveyInitialData(survey, answers);
    const { pageNumber } = answers;
    if (pageNumber && pageNumber > -1) {
      survey.currentPageNo = pageNumber;
    }

    if (isReadonlyView) {
      replaceChoicesUrlParamValuesWithAnswers(
        answers,
        survey.getAllQuestions()
      );
      survey.mode = "display";
      survey.questionsOnPageMode = "singlePage";
    }

    if (!isReadonlyView) {
      survey.start();
    }

    return {
      survey,
      gatherProcessId,
      answers,
      apiService,
      contentQuestion,
      sidebarContent,
    };
  } else {
    let answers = null;
    if (apiService.resourceType === "product-questionnaire") {
      const { data } = await apiService.fetchAnswers();
      answers = data;
      setSurveyInitialData(survey, answers);
    }
    return {
      survey,
      gatherProcessId,
      answers,
      apiService,
      contentQuestion,
      sidebarContent,
    };
  }
}) satisfies LoaderFunction;

export const SurveyPage = () => {
  const { survey, apiService, contentQuestion, sidebarContent } =
    useLoaderData<typeof surveyPageLoader>();
  const pendingQuestionsRef = useRef(new Map<string, any>()); //this is used to store the pending to be saved questions that are not yet saved and their last known good values
  // TODO: @Amit this is commented because it is not used. please lets check if this is needed at all so I can remove it from this branch
  // const menuItems = getSideBarItems(survey, apiService.resourceType);
  useHydrateAtoms([
    [isNewQuestionnaireAtom, survey.isShowStartingPage],
    [questionnaireNameAtom, survey.title],
    [languageQuestionAtom, getLanguageDropdownQuestion(survey)],
    [explainerContentQuestionAtom, contentQuestion as QuestionExpressionModel],
    [sidebarContentAtom, sidebarContent],
    [
      facilityDataAtom,
      {
        facilityShortName: survey.getPropertyValue("facilityShortName"),
        brandName: survey.getPropertyValue("brandName"),
      },
    ],
  ]);
  const uploadedFilesSet = useAtomValue(uploadedFilesSetAtom);
  const [activePage, setActivePage] = useState<PageModel>(survey.activePage);
  const [showSideBar, setShowSideBar] = useState(
    !survey.isShowStartingPage &&
      survey.state !== "completed" &&
      survey.state !== "preview" &&
      !isDisplayMode(survey)
  );
  const [isNewQuestionnaire, setIsNewQuestionnaire] = useAtom(
    isNewQuestionnaireAtom
  );
  const [showNavButtons, setShowNavButton] = useState(true);

  const canPerformChanges = useCallback(
    () => survey.state === "running" && !isDisplayMode(survey),
    [survey]
  );

  const setSurveyCurrentPage = useCallback(
    (name: string) => {
      survey.currentPage = survey.getPageByName(name);
    },
    [survey]
  );

  const onStarted = useCallback((sender: Model) => {
    sender.pageNextText = "%-%tolgee:button-begin-questionnaire%-%";
    setShowSideBar(false);
    setActivePage(sender.activePage);
  }, []);

  useEffect(() => {
    const onValueChanging = (
      _: SurveyModel,
      { question, value, oldValue, name }: ValueChangingEvent
    ) => {
      let rawValue: any;

      if (
        !canPerformChanges() ||
        !apiService.questionnaireId ||
        isFile(question) ||
        isRemovingRowFromDynamicPanelOrMatrix(question, oldValue, value) ||
        !question
      ) {
        return;
      }

      if (isDynamicPanel(question)) {
        const result = compareFilteredChanges(
          value,
          oldValue,
          uploadedFilesSet,
          removeKeysFromArray
        );
        if (!result) return;
      } else if (isMatrixDropdown(question) || isMatrixDynamic(question)) {
        const result = compareFilteredChanges(
          value,
          oldValue,
          uploadedFilesSet,
          removeKeysFromObject
        );
        if (!result) return;
      }

      //if a save is already pending, update the pending value
      if (pendingQuestionsRef.current.has(name)) {
        pendingQuestionsRef.current.set(name, {
          pendingValue: value,
          lastKnownGoodValue:
            pendingQuestionsRef.current.get(name).lastKnownGoodValue,
        });
      } else {
        //otherwise, set the pending value and the last known good value
        pendingQuestionsRef.current.set(name, {
          pendingValue: value,
          lastKnownGoodValue: oldValue,
        });
      }

      if (isDropdown(question) || isBoolean(question)) {
        rawValue = pendingQuestionsRef.current.get(name).pendingValue ?? "";
      } else {
        rawValue = JSON.parse(
          JSON.stringify(pendingQuestionsRef.current.get(name).pendingValue)
        );
      }

      apiService
        .saveAnswer(name, rawValue)
        .then(() => {
          //if the save is successful, delete the pending value
          pendingQuestionsRef.current.delete(name);
        })
        .catch((e) => {
          //if the save fails, restore the last known good value
          const { lastKnownGoodValue } =
            pendingQuestionsRef.current.get(name) || {};
          question.value = lastKnownGoodValue;
          pendingQuestionsRef.current.delete(name);
          toast.error(() => <T keyName="save-answer-fail-error-message" />, {
            icon: <XCircle color="var(--error-500)" />,
          });
        });
    };
    survey.onValueChanging.add(onValueChanging);
    return () => {
      survey.onValueChanging.remove(onValueChanging);
    };
  }, [apiService, canPerformChanges, survey, uploadedFilesSet]);

  const onShowingPreview = useCallback(() => {
    setShowSideBar(false);
  }, []);

  const onUpdateQuestionCssClasses = useCallback(
    (
      _: SurveyModel,
      { cssClasses, question }: UpdateQuestionCssClassesEvent
    ) => {
      if (isExpression(question)) {
        if (isPageTitle(question)) cssClasses.mainRoot += " !px-0"; //set x-axis padding to none
      } else if (isCheckbox(question)) {
        cssClasses.title += " !text-sm"; //force big font size (even if on mobile)
      }
    },
    []
  );

  const onLoadChoicesFromServer = useCallback(
    (_: SurveyModel, { question, choices }: LoadChoicesFromServerEvent) => {
      if (question.name.includes("facility-state-province")) {
        question.showNoneItem = choices.length < 1;
      }
    },
    []
  );

  useEffect(() => {
    const allowDynamicPanelRemovingMap = new Map<
      string,
      Map<number, boolean>
    >();

    const onDynamicPanelRemoving = (
      _: SurveyModel,
      options: DynamicPanelRemovingEvent
    ) => {
      if (
        !canPerformChanges() ||
        options.question.minPanelCount === options.question.panels.length
      ) {
        return;
      }
      const sectionValueName = options.question.getValueName();

      let questionFlagsMap = allowDynamicPanelRemovingMap.get(sectionValueName);
      let allowRemoving = false;

      if (questionFlagsMap) {
        const tmp = questionFlagsMap.get(options.panelIndex);
        if (tmp) allowRemoving = tmp;
        else {
          questionFlagsMap.set(options.panelIndex, allowRemoving);
        }
      } else {
        questionFlagsMap = new Map();
        questionFlagsMap.set(options.panelIndex, false);
        allowDynamicPanelRemovingMap.set(sectionValueName, questionFlagsMap);
      }

      options.allow = allowRemoving;

      if (!allowRemoving) {
        removingDynamicPanelToast(
          apiService.removeGroupByIndex(sectionValueName, options.panelIndex)
        )
          .then(() => {
            questionFlagsMap?.set(options.panelIndex, true);
            options.question.removePanel(options.panelIndex);
          })
          .catch();
      } else {
        //reset the flag
        questionFlagsMap?.set(options.panelIndex, false);
      }
    };
    survey.onDynamicPanelRemoving.add(onDynamicPanelRemoving);
    return () => {
      survey.onDynamicPanelRemoving.remove(onDynamicPanelRemoving);
    };
  }, [survey, canPerformChanges, apiService]);

  useEffect(() => {
    const allowRowRemovingMap = new Map<string, Map<number, boolean>>();
    const onMatrixRowRemoving = (_: Model, options: MatrixRowRemovingEvent) => {
      if (
        !canPerformChanges() ||
        options.question.minRowCount === options.question.rows.length
      ) {
        return;
      }

      const sectionValueName = options.question.getValueName();

      let questionFlagsMap = allowRowRemovingMap.get(sectionValueName);
      let allowRemoving = false;

      if (questionFlagsMap) {
        const tmp = questionFlagsMap.get(options.rowIndex);
        if (tmp) allowRemoving = tmp;
        else {
          questionFlagsMap.set(options.rowIndex, allowRemoving);
        }
      } else {
        questionFlagsMap = new Map();
        questionFlagsMap.set(options.rowIndex, false);
        allowRowRemovingMap.set(sectionValueName, questionFlagsMap);
      }

      options.allow = allowRemoving;

      if (!allowRemoving) {
        removingMatrixRowToast(
          apiService.removeGroupByIndex(sectionValueName, options.rowIndex)
        )
          .then(() => {
            questionFlagsMap?.set(options.rowIndex, true);
            options.question.removeRow(options.rowIndex);
          })
          .catch();
      } else {
        //reset the flag
        questionFlagsMap?.set(options.rowIndex, false);
      }
    };
    survey.onMatrixRowRemoving.add(onMatrixRowRemoving);
    return () => {
      survey.onMatrixRowRemoving.remove(onMatrixRowRemoving);
    };
  }, [apiService, canPerformChanges, survey]);

  useEffect(() => {
    survey.onLoadChoicesFromServer.add(onLoadChoicesFromServer);
    return () => survey.onLoadChoicesFromServer.remove(onLoadChoicesFromServer);
  }, [onLoadChoicesFromServer, survey]);

  useEffect(() => {
    survey.onUpdateQuestionCssClasses.add(onUpdateQuestionCssClasses);
    return () =>
      survey.onUpdateQuestionCssClasses.remove(onUpdateQuestionCssClasses);
  }, [onUpdateQuestionCssClasses, survey.onUpdateQuestionCssClasses]);

  useEffect(() => {
    survey.onShowingPreview.add(onShowingPreview);
    return () => {
      survey.onShowingPreview.remove(onShowingPreview);
    };
  }, [survey, onShowingPreview]);

  useEffect(() => {
    const onComplete = (sender: SurveyModel) => {
      setShowSideBar(false);
      setShowNavButton(false);
    };

    survey.onComplete.add(onComplete);
    return () => {
      survey.onComplete.remove(onComplete);
    };
  }, [survey]);

  useEffect(() => {
    let allowComplete = false;
    const onCompleting = (sender: SurveyModel, options: CompletingEvent) => {
      options.allow = allowComplete;
      sender.currentPageNo = 0;
      sender.mode = "display";
      setShowSideBar(false);
      setShowNavButton(false);
      if (!allowComplete) {
        completeQuestionnaireToast(
          apiService.completeQuestionnaire(
            getAllVisibleQuestionValueNames(survey),
            sender.data
          )
        )
          .then(() => {
            sessionStorage.clear();
            allowComplete = true;
            sender.completeLastPage();
          })
          .catch(() => {
            setShowSideBar(true);
            setShowNavButton(true);
          })
          .finally(() => {
            sender.mode = "edit";
          });
      } else {
        allowComplete = false;
      }
    };
    survey.onCompleting.add(onCompleting);
    return () => {
      survey.onCompleting.remove(onCompleting);
    };
  }, [apiService, survey]);

  useEffect(() => {
    survey.onStarted.add(onStarted);
    return () => {
      survey.onStarted.remove(onStarted);
    };
  }, [survey, onStarted]);

  useEffect(() => {
    const onBeforeUnload = (event: BeforeUnloadEvent) => {
      if (apiService.pending) {
        event.preventDefault();
      }
    };

    window.addEventListener("beforeunload", onBeforeUnload);
    return () => window.removeEventListener("beforeunload", onBeforeUnload);
  }, [apiService.pending]);

  /**
   * make sure state-province options includes None everytime after country value is changing
   * and there are no pre-loaded option for state-province
   */
  useEffect(() => {
    const onValueChanged = (
      survey: SurveyModel,
      { question }: ValueChangedEvent
    ) => {
      if (isDropdown(question) && question.name.includes("facility-country")) {
        const stateQuestion = survey
          .getAllQuestions()
          .find((q) => q.name.includes("facility-state-province"));
        if (stateQuestion)
          (stateQuestion as QuestionDropdownModel).showNoneItem =
            (stateQuestion as QuestionDropdownModel).choices.length < 1;
      }
    };
    survey.onValueChanged.add(onValueChanged);
    return () => {
      survey.onValueChanged.remove(onValueChanged);
    };
  }, [survey.onValueChanged]);

  const saveLanguageOnNewQuestionnaire = useCallback(() => {
    const languageQuestion = getLanguageDropdownQuestion(survey);
    if (languageQuestion)
      apiService
        .saveAnswer(languageQuestion.valueName, languageQuestion.value)
        .catch((e) => {
          toast.error(() => <T keyName="save-answer-fail-error-message" />, {
            icon: <XCircle color="var(--error-500)" />,
          });
        });
  }, [apiService, survey]);

  useEffect(() => {
    let allowPageChange = false;
    const onCurrentPageChanging = (
      sender: SurveyModel,
      options: CurrentPageChangingEvent
    ) => {
      prepareQuestions(options.newCurrentPage.questions);
      if (options.oldCurrentPage.name === "explainer-page") {
        options.allow = allowPageChange;
        if (!allowPageChange) {
          generateQuestionnaireIdToast(
            apiService.createQuestionnaire(sender.data),
            apiService.resourceType
          )
            .then(async (res) => {
              sessionStorage.setItem(
                "questionnaire-id-key",
                res.data.questionnaireId
              );
              sender.pageNextText = "%-%tolgee:button-next%-%";
              setIsNewQuestionnaire(false);
              allowPageChange = true;
              saveLanguageOnNewQuestionnaire();
              survey.nextPage();
            })
            .catch();
        } else {
          allowPageChange = true;
        }
      } else {
        apiService.savePageNumber(options.newCurrentPage.num - 1).catch();
      }
    };

    survey.onCurrentPageChanging.add(onCurrentPageChanging);
    return () => {
      survey.onCurrentPageChanging.remove(onCurrentPageChanging);
    };
  }, [
    apiService,
    saveLanguageOnNewQuestionnaire,
    setIsNewQuestionnaire,
    survey,
  ]);

  useEffect(() => {
    const onCurrentPageChanged = (
      sender: SurveyModel,
      { newCurrentPage }: CurrentPageChangedEvent
    ) => {
      setShowSideBar(true);
      setActivePage(sender.activePage);
      sender.removePage(sender.getPageByName("explainer-page"));
    };
    survey.onCurrentPageChanged.add(onCurrentPageChanged);
    return () => {
      survey.onCurrentPageChanged.remove(onCurrentPageChanged);
    };
  }, [survey]);

  return (
    <ApiServiceProvider apiService={apiService}>
      <MenuDrawerTemplate
        items={[]}
        disabled={!showSideBar}
        activePageName={activePage.name}
        onItemClick={setSurveyCurrentPage}
      />
      <SurveyPageTemlpate
        menuProps={{
          disabled: !apiService.questionnaireId,
          activePageName: activePage.name,
          onItemClick: setSurveyCurrentPage,
        }}
        survey={survey}
        isNewQuestionnaire={isNewQuestionnaire}
        showSideBar={
          survey.getPropertyValue("navigationMenu") === "sidebar" && showSideBar
        }
        showNavButtons={showNavButtons}
      />
    </ApiServiceProvider>
  );
};

const generateQuestionnaireIdToast = (
  promiseFunc: Promise<AxiosResponse>,
  resourceType: ResourceType
) => {
  return toast.promise(promiseFunc, {
    pending: { render: () => <T keyName="generate-qid-pending" /> },
    success: {
      render: () => (
        <T
          keyName={
            resourceType === "facility-questionnaire"
              ? "dgq-generate-qid-success"
              : "bpq-generate-qid-success"
          }
        />
      ),
      icon: <CheckCircle color="var(--success-500)" />,
      autoClose: false,
    },
    error: {
      render: () => <T keyName="generate-qid-fail" />,
      icon: <XCircle color="var(--error-500)" />,
    },
  });
};

const completeQuestionnaireToast = (promiseFunc: Promise<AxiosResponse>) => {
  return toast.promise(promiseFunc, {
    pending: { render: () => <T keyName="complete-questionnaire-pending" /> },
    success: {
      render: () => <T keyName="complete-questionnaire-success" />,
      icon: <CheckCircle color="var(--success-500)" />,
    },
    error: {
      onClose: () => window.location.reload(),
      render: () => <T keyName="complete-questionnaire-fail" />,
      icon: <XCircle color="var(--error-500)" />,
    },
  });
};

const removingMatrixRowToast = (promiseFunc: Promise<AxiosResponse>) => {
  return toast.promise(promiseFunc, {
    error: {
      render: () => <T keyName="removing-row-error-message" />,
      icon: <XCircle color="var(--error-500)" />,
    },
  });
};

const removingDynamicPanelToast = (promiseFunc: Promise<AxiosResponse>) => {
  return toast.promise(promiseFunc, {
    error: {
      render: () => <T keyName="removing-panel-error-message" />,
      icon: <XCircle color="var(--error-500)" />,
    },
  });
};

function getLanguageDropdownQuestion(survey: SurveyModel) {
  const introPage = survey.startedPage;
  const languageQuestion = introPage.questions.find((q) =>
    q.name.includes("language")
  );
  return languageQuestion;
}
