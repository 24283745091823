import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import {
  DevTools,
  Tolgee,
  TolgeeProvider,
  BackendFetch,
  ObserverPlugin,
  LanguageStorage,
  FormatSimple,
} from "@tolgee/react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Loading } from "./components/ui/loading";
import "./index.css";
import { AxiosError } from "axios";
import { Provider } from "jotai";
import { appConfig } from "./config";
import {
  SurveyPage,
  surveyPageLoader,
  ErrorPage,
  App,
  CompletePage,
} from "./pages";

const tolgee = Tolgee()
  .use(DevTools())
  .use(FormatSimple())
  .use(BackendFetch())
  .use(ObserverPlugin())
  .use(LanguageStorage())
  .init({
    defaultLanguage: "en",
    availableLanguages: appConfig.availableLanguages,
    // for development
    apiUrl: appConfig.tolgeeApiUrl,
    apiKey: appConfig.tolgeeApiKey,
    observerType: "text",
    fallbackLanguage: 'en',
    observerOptions: {
      tagAttributes: {
        td: ["data-responsive-title"],
        input: ["placeholder", "value"],
        textarea: ["placeholder"],
        "*": ["aria-label", "title"],
      },
    },
  });

const router = createBrowserRouter([
  {
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        loader: async () => {
          throw new AxiosError("use-provided-link-error-message");
        },
      },
      {
        path: "/:gatherProcessId",
        element: <SurveyPage />,
        loader: surveyPageLoader,
      },
      {
        path: "/complete",
        element: <CompletePage />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider>
      <TolgeeProvider
        tolgee={tolgee}
        fallback={<Loading message="Loading languages" />}
      >
        <ToastContainer />
        <RouterProvider router={router} fallbackElement={<Loading />} />
      </TolgeeProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
