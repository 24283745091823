import { Report } from "../types";
import { countColumnsWithValues } from "../utils";

export function validateTotalSelectedRows(
  params: Array<Report | null>
): number {
  return countColumnsWithValues(params, [
    "has-type",
    "has-method",
    "uses-dye",
    "factory-has-type-tier-2",
    "dyes-used-in-factory",
    "wet-process-has-type",
    "mechanical-process-has-process",
    "printing-process-has-process",
    "crp-ext-spinning-sub-processes-has-sub-processes-column-tier-3",
    "factory-has-type-tier-3",
    "mechanically-recycled-type-has-type",
    "chemically-recycled-type-has-type",
  ]);
}
