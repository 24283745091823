import { useAtomValue } from "jotai";
import { FC, createElement } from "react";
import { QuestionExpressionModel, RendererFactory } from "survey-core";
import { ReactQuestionFactory } from "survey-react-ui";
import { facilityDataAtom } from "../../jotai-atoms";

interface IntroPageTitleProps {
  question: QuestionExpressionModel;
}

const IntroPageTitle: FC<IntroPageTitleProps> = ({ question }) => {
  const facilityData = useAtomValue(facilityDataAtom);
  const { title } = question;

  const facilityShortName = facilityData?.facilityShortName;
  const brandName = facilityData?.brandName;

  return brandName && facilityShortName ? (
    <div className="flex flex-col gap-[15px] w-full">
      <div className="flex flex-row items-center justify-between w-full">
        <div className="text-xl font-semibold text-gray-800 text-wrap">
          {title}
        </div>
        <div className="text-lg font-normal text-gray-800 uppercase text-wrap">
          {brandName}
        </div>
      </div>
      <div className="text-xl font-semibold text-gray-800 text-wrap">
        {facilityShortName}
      </div>
    </div>
  ) : (
    <div className="font-medium leading-6 text-gray-800 md:text-display-xs text-md text-wrap">
      {title}
    </div>
  );
};

ReactQuestionFactory.Instance.registerQuestion(
  "sv-intro-page-title",
  function (props: any) {
    return createElement(IntroPageTitle, props);
  }
);

RendererFactory.Instance.registerRenderer(
  "expression",
  "intro-page-title",
  "sv-intro-page-title"
);
