import { FunctionFactory } from "survey-core";
import { validateProductHasMainFabric } from "./validate-main-fabric";
import { validateTotal } from "./validate-total";
import { validateTotalSelectedRows } from "./validate-num-of-selected-rows";

FunctionFactory.Instance.register(
  "validateProductHasMainFabric",
  validateProductHasMainFabric
);
FunctionFactory.Instance.register("validateTotal", validateTotal);
FunctionFactory.Instance.register(
  "validateTotalSelectedRows",
  validateTotalSelectedRows
);
