/* eslint-disable no-process-env */

import { appConfigSchema } from "./types";

export const appConfig = getAppConfig();

function getAppConfig() {
  return appConfigSchema.parse({
    surveyApi: process.env.REACT_APP_API_URL,
    tolgeeApiUrl: process.env.REACT_APP_TOLGEE_API_URL,
    tolgeeApiKey: process.env.REACT_APP_TOLGEE_API_KEY,
    availableLanguages: JSON.parse(
      process.env.REACT_APP_AVAILABLE_LANGUAGES || '["en"]'
    ),
    paginationConfig: {
      maxItemsPerPage: parseInt(process.env.REACT_APP_PAGINATION_MAX_ITEMS_PER_PAGE || '30'),
      itemsPerPage: parseInt(process.env.REACT_APP_PAGINATION_ITEMS_PER_PAGE || '5'),
      selectedPage: parseInt(process.env.REACT_APP_PAGINATION_SELECTED_PAGE || '0'),
      displayInterval: parseInt(process.env.REACT_APP_PAGINATION_DISPLAY_INTERVAL || '5'),
      pageCounter: parseInt(process.env.REACT_APP_PAGINATION_PAGE_COUNTER || '0'),
    },
    maxFileSize: parseInt(process.env.REACT_APP_MAX_FILE_SIZE as string),
  });
}
