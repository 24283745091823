import { Report } from "../types";
import { getProductionRatio, hasRatioField, sumOf } from "../utils";

export const validateTotal = (params: Report[]) => {
  const ratioFields = [
    "ratio-tier-3-yarn",
    "ratio-tier-2-fabric-knitting",
    "ratio-tier-2-fabric-weaving",
    "ratio-tier-2-fabric-general",
    "ratio-tier-1-garment",
  ];

  if (params && params.length > 0 && hasRatioField(params[0], ratioFields)) {
    return getProductionRatio(params);
  }

  return sumOf(params, [
    "total-fiber-types",
    "total-dyeing",
    "total-spinning",
    "total-fabric-compositions",
    "total-wet-processes",
    "total-garment-types",
    "total-production-range",
    "total-dyeing-types",
  ]);
};
